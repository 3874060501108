import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NoShoppingCartModal, NoUserLoginModal } from "./Modal";

export default function BottomNavBar() {
  const navigate = useNavigate();

  const location = useLocation();
  console.log(location.pathname);

  let shoppingCartPageData =
    JSON.parse(localStorage.getItem("shoppingCart") as string) || undefined;
  let menuId = 0;
  if (shoppingCartPageData) {
    menuId = shoppingCartPageData.shopId;
  }

  const [noUserLoginModal, setNoUserLoginModal] = useState(false);
  const onNavHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (localStorage.getItem("token") === null) {
      e.preventDefault();
      setNoUserLoginModal(true);
    }
  };
  const onLoginHandler = () => {
    console.log("hi");
    setNoUserLoginModal(false);

    navigate("/client-login");
  };

  if (!location.pathname.includes("itemPage")) {
    return (
      <div className="fixed bottom-0 left-0 right-0 w-full h-auto bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
        <div className=" justify-around flex m-3">
          <Link to="/">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-0 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500">
                主頁{" "}
              </span>{" "}
            </button>
          </Link>
          <Link
            to={shoppingCartPageData ? `/menu/${menuId}` : "/shopSelection"}
          >
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-0 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z" />
                <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z" />
              </svg>
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500">
                訂購
              </span>{" "}
            </button>
          </Link>

          <Link to="/shoppingCart">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-0 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                />
              </svg>
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500">
                購物車
              </span>{" "}
            </button>
          </Link>
          <Link onClick={(e) => onNavHandler(e)} to="/myPage">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-0 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-green-600 dark:group-hover:text-green-500">
                我的
              </span>
            </button>
          </Link>
        </div>
        <NoUserLoginModal
          show={noUserLoginModal}
          onClose={() => setNoUserLoginModal(false)}
          onLogin={onLoginHandler}
        />
      </div>
    );
  } else {
    return <></>;
  }
}
